import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import loadable from "@loadable/component";
import RouteHeader from "./components/RouteHeader";
import ReturnAuth from "./views/Auth/ReturnAuth";
const ConectInstagram = loadable(() => import("./views/ConectInstagram"));
const Home = loadable(() => import("./views/Home/Home"));
const HomeInstagram = loadable(() => import("./views/Instagram/HomeInstagram"));

function App() {
  return (
    <Router>
      <Switch>
        <RouteHeader exact path="/" component={Home} />
        <RouteHeader exact path="/load-instagram" component={ConectInstagram} />
        <RouteHeader exact path="/view-instagram" component={HomeInstagram} />
        <RouteHeader exact path="/auth_login" component={ReturnAuth} />

        <Redirect to="/" />
        {/* si el path no hace match con alguna de las rutas anteriores lo redirige al home */}
      </Switch>
    </Router>
  );
}

export default App;
