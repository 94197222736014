import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import axios from 'axios';
import { useInitFbSDK } from "../fb-hooks";
import { useHistory } from "react-router-dom";

const drawerWidth = 300;
const useStyles = makeStyles({
  navBar: {
    backgroundColor: "#1A2733 !important",
    position: "relative !important",
    top: 0,
    width: "100%",
    zIndex: 1000,
    height: "100px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
    width: "100%",
  },
  botonera: {
    display: "flex",
    flexDirection: "column",
    margin: "30px",
    height: "100px",
  },
  botonText: {
    color: "#C0E1E8",
    fontFamily: "",
    textShadow: "1px 1px 2px #003A68",
  },
  botonDrawer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly !important",
  },
  botonLogIn:{

  }
});

export default function MiniDrawer({ window, children }) {
  const classes = useStyles();
  const history = useHistory()
  const matches = useMediaQuery("(min-width:900px)");

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  return (
    <Box>
      <CssBaseline />
      <AppBar position="fixed" open={matches} className={classes.navBar}>
        <Toolbar sx={{ height: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: "36px",
            }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h4" noWrap component="div">
            Metricy
          </Typography>
        </Toolbar>
      </AppBar>
      <section className={classes.container}>
        <Drawer
          sx={{
            width: drawerWidth,
            height: "100%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              border: "none",
              position: "relative",
              height: "100vh",
              left: 0,
              boxShadow: "1px 1px 1px 1px #e2e8f0",
              background: "linear-gradient(45deg, #0074AA 30%, #00CFC1  90%)",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <div className={classes.botonera}>
            <Button className={classes.botonDrawer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-brand-facebook"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="#0073B1"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
              </svg>
              <Typography variant="h8" noWrap className={classes.botonText}>Facebook</Typography>
            </Button>
            <Button className={classes.botonDrawer} onClick={() => history.push("/load-instagram")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-brand-instagram"
                width="40"
                height="40"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="#E53F61"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="4" y="4" width="16" height="16" rx="4" />
                <circle cx="12" cy="12" r="3" />
                <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
              </svg>
              <Typography variant="h8" noWrap className={classes.botonText}>Instagram</Typography>
            </Button>
          </div>
        </Drawer>
        {children}
      </section>
    </Box>
  );
}
