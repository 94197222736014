import React, { useEffect } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { useInitFbSDK } from "../../fb-hooks";

const ReturnAuth = () => {
  const { search } = useLocation();
  const isFbSDKInitialized = useInitFbSDK();
  useEffect(() => {
    let code = search.replace("?code=", "");
    console.log(code);
    window.onload = function () {
      axios
        .get(
          `https://graph.facebook.com/v12.0/oauth/access_token?redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fauth_login&client_id=478183183624807&client_secret=08dcb4f0493c6102756f00454f22543c&code=${code}`
        )
        .then(async (data) => {
          console.log("DATAAAAAAA", data);
          // setFbUserAccessToken(data.accessToken);

          try {
            let postToken = await axios({
              method: "POST",
              url: "http://localhost:4000/api/pruebas/login",
              data: { token: data.access_token },
            });
            console.log(postToken);
          } catch (error) {
            console.log("ERROR:", error)
          }

          await window.FB.logout();
          window.close();
        });
    };

    // setTimeout(() => {

    // }, 20000);
  }, []);
  return <div></div>;
};

export default ReturnAuth;
