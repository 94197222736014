import React from "react";
import { Route } from "react-router-dom";
import Header from "./Header";

function RouteWithHeader({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Header>
          <Component {...props} />
        </Header>
      )}
    />
  );
}

export default RouteWithHeader;
